import './App.css';
import rod from './images/rod.png';
import Fish from './Components/Fish';
import { useState } from 'react';

function App() {
  const [nb, setNb] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const fishLuck = {
    goldfish: [20, "green"],
    salmon: [14, "#069c06"],
    blobfish: [10, "#0be30b"],
    koifish: [10, "#0be30b"],
    guppy: [10, "#0be30b"],
    minecraftfish: [5, "#e1fa02"],
    bufffish: [4, "#f2ea07"],
    gayfish: [3.5, "#f2cf07"],
    nerdfish: [2.4, "#ff5f03"],
  };

  const handleClick = () => {
    setNb(Math.random()*100);
    setAttempts(attempts + 1);
  }

  return (
    <div className='jl'>
      {/* <div style={{textAlign: "right", padding: "3%"}}>
        {nb.toFixed(2)} %
      </div> */}
      <div>
        <div className='luck-table'>
          <table>
            <thead>
              <tr>
                <td>Fish Type</td>
                <td>Count</td>
              </tr>
            </thead>
            <tbody>
              {Object.keys(fishLuck).map((fishType) => (
              <tr key={fishType} style={{backgroundColor: fishLuck[fishType][1]}}>
                <td>{fishType}</td>
                <td>{fishLuck[fishType][0]}%</td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <h3>Total lines thrown: {attempts}</h3>
        </div>
      </div>
      <img src={rod} className='rodS' alt='rod' onClick={handleClick}></img>
      <Fish nb = {nb}></Fish>
      
    </div>
  );
}

export default App;
