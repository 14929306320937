import goldfish from '../images/goldie.png';
import blobfish from '../images/blob.png';
import koifish from '../images/koi.png';
import guppy from '../images/guppy.png';
import salmon from '../images/salmon.png';
import bufffish from '../images/buff.png';
import minecraftfish from '../images/mc.png';
import gayfish from '../images/gayy.png';
import nerdfish from '../images/nerd.png';
import { useEffect, useState } from 'react';

function Fish(props){
    // const number = Math.random(1000)*100;
    const [fishCounts, setFishCounts] = useState({
        goldfish: 0,
        salmon: 0,
        blobfish: 0,
        koifish: 0,
        guppy: 0,
        minecraftfish: 0,
        bufffish: 0,
        gayfish: 0,
        nerdfish: 0,
      });
    // useEffect(() => {
    //     setFishCounts(fishCounts)
    // }, [fishCounts]);
    
      const handleFind = (fishType) => {
        // Increment the count for the specific fish type
        setFishCounts((prevCounts) => ({
          ...prevCounts,
          [fishType]: prevCounts[fishType] + 1,
        }));
      };

    return(<div>
        
        {props.nb >= 0 &&  props.nb <= 10 && <img src={blobfish} className="fishies" onLoad={() => handleFind('blobfish')}></img>}
        {props.nb > 10 &&  props.nb <= 30 && <img src={goldfish} className="fishies" onLoad={() => handleFind('goldfish')}></img>}
        {props.nb > 30 && props.nb <= 40 && <img src={koifish} className="fishies" onLoad={() => handleFind('koifish')}></img>}
        {props.nb > 40 &&  props.nb <= 50 && <img src={guppy} className="fishies" onLoad={() => handleFind('guppy')}></img>}
        {props.nb > 50 && props.nb <= 64 && <img src={salmon} className="fishies" onLoad={() => handleFind('salmon')}></img>}
        {props.nb > 64 && props.nb <= 69 && <img src={minecraftfish} className="fishies" onLoad={() => handleFind('minecraftfish')}></img>}
        {props.nb >69 && props.nb <= 73 &&  <img src={bufffish} className="fishies" onLoad={() => handleFind('bufffish')}></img>}
        {props.nb > 73 && props.nb <= 76.5 && <img src={gayfish} className="fishies" onLoad={() => handleFind('gayfish')}></img>}
        {props.nb > 76.5 && props.nb <= 78.9 && <img src={nerdfish} className="fishies" onLoad={() => handleFind('nerdfish')}></img>}
        {/* 2.4%        <img src={props.nb > 60 &&  nerdfish} className="fishies"></img> //add glasses
            5%        //Cat fish
            5%        //Fish cat
            5%        //dog fish
            5%        //Chinese fish         if cat/dog before, + 5% increase
            1%        //Cock fish
            0.1%       //boob fish
        */}
        <p style={{marginLeft: "2%"}}>{props.nb.toFixed(2)} % </p>
        <div className='count'>
            <table>
                <thead>
                    <tr>
                        <td>Fish Type</td>
                        <td>Count</td>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(fishCounts).map((fishType) => (
                    <tr key={fishType}>
                        <td>{fishType}</td>
                        <td>{fishCounts[fishType]}</td>
                    </tr>
                    ))}
                </tbody>
            </table>   
        </div>
        {/* <table>
                <tr>
                    <td>Blobfish</td>
                    <td>Goldfish</td>
                    <td>Koifish</td>
                    <td>Guppy</td>
                    <td>Salmon</td>
                    <td>Mincraft Fish</td>
                    <td>Buff Fish</td>
                    <td>Gay Fish</td>
                    <td>Nerd Fish</td>
                </tr>
                <tr>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </table> */}
    </div>)
}

export default Fish;